@media only screen and (min-width: 813px) {
        

    @keyframes fade-out {
        from {
            opacity: 100;
        }
        
        to {
            opacity: 0;
        }
    }

    @keyframes fade-in {
        from {opacity: 0;}

        to {opacity: 100;}
    }


    .bird {
        background-image: url('../assets/bird-cells.svg');
        background-size: auto 100%;
        width: 88px;
        height: 125px;
        will-change: background-position;
        -webkit-animation-name: fly-cycle;
        animation-name: fly-cycle;
        -webkit-animation-timing-function: steps(10);
        animation-timing-function: steps(10);
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        z-index: 100;
    }
    .bird--one {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
    }
    .bird--two {
        -webkit-animation-duration: 0.9s;
        animation-duration: 0.9s;
        -webkit-animation-delay: -0.75s;
        animation-delay: -0.75s;
    }

    .bird-container {
        position: absolute;
        top: 10%;
        left: -7.5vw;
        max-width: 100%;
        -webkit-transform: scale(0);
        transform: scale(0);
        will-change: transform;
        -webkit-animation-name: fly-right-one;
        animation-name: fly-right-one;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }
    .bird-container--one {
        -webkit-animation-duration: 15s;
        animation-duration: 15s;
        -webkit-animation-delay: 0;
        animation-delay: 0;
    }
    .bird-container--two {
        -webkit-animation-duration: 16s;
        animation-duration: 16s;
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
    }

    @-webkit-keyframes fly-cycle {
        100% {
        background-position: -900px 0;
       }
       }
        @keyframes fly-cycle {
        100% {
        background-position: -900px 0;
       }
       }
       @-webkit-keyframes fly-right-one {
        0% {
        left: -10%;
        -webkit-transform: scale(0.3);
        transform: scale(0.3);
       }
        10% {
        left: 10%;
        -webkit-transform: translateY(2vh) scale(0.4);
        transform: translateY(2vh) scale(0.4);
       }
        20% {
        left: 30%;
        -webkit-transform: translateY(0vh) scale(0.5);
        transform: translateY(0vh) scale(0.5);
       }
        30% {
        left: 50%;
        -webkit-transform: translateY(4vh) scale(0.6);
        transform: translateY(4vh) scale(0.6);
       }
        40% {
        left: 70%;
        -webkit-transform: translateY(2vh) scale(0.6);
        transform: translateY(2vh) scale(0.6);
       }
        50% {
        left: 90%;
        -webkit-transform: translateY(0vh) scale(0.6);
        transform: translateY(0vh) scale(0.6);
       }
        60% {
        left: 110%;
        -webkit-transform: translateY(0vh) scale(0.6);
        transform: translateY(0vh) scale(0.6);
       }
        100% {
        left: 110%;
        opacity: 1;
        -webkit-transform: translateY(0vh) scale(0.6);
        transform: translateY(0vh) scale(0.6);
       }
       }
       @keyframes fly-right-one {
        0% {
        left: -10%;
        -webkit-transform: scale(0.3);
        transform: scale(0.3);
       }
        10% {
        left: 10%;
        -webkit-transform: translateY(2vh) scale(0.4);
        transform: translateY(2vh) scale(0.4);
       }
        20% {
        left: 30%;
        -webkit-transform: translateY(0vh) scale(0.5);
        transform: translateY(0vh) scale(0.5);
       }
        30% {
        left: 50%;
        -webkit-transform: translateY(4vh) scale(0.6);
        transform: translateY(4vh) scale(0.6);
       }
        40% {
        left: 70%;
        -webkit-transform: translateY(2vh) scale(0.6);
        transform: translateY(2vh) scale(0.6);
       }
        50% {
        left: 90%;
        -webkit-transform: translateY(0vh) scale(0.6);
        transform: translateY(0vh) scale(0.6);
       }
        60% {
        left: 110%;
        -webkit-transform: translateY(0vh) scale(0.6);
        transform: translateY(0vh) scale(0.6);
       }
        100% {
        left: 110%;
        opacity: 1;
        -webkit-transform: translateY(0vh) scale(0.6);
        transform: translateY(0vh) scale(0.6);
       }
       }
       @-webkit-keyframes fly-right-two {
        0% {
        left: -10%;
        opacity: 1;
        -webkit-transform: translateY(-2vh) scale(0.5);
        transform: translateY(-2vh) scale(0.5);
       }
        10% {
        left: 10%;
        -webkit-transform: translateY(0vh) scale(0.4);
        transform: translateY(0vh) scale(0.4);
       }
        20% {
        left: 30%;
        -webkit-transform: translateY(-4vh) scale(0.6);
        transform: translateY(-4vh) scale(0.6);
       }
        30% {
        left: 50%;
        -webkit-transform: translateY(1vh) scale(0.45);
        transform: translateY(1vh) scale(0.45);
       }
        40% {
        left: 70%;
        -webkit-transform: translateY(-2.5vh) scale(0.5);
        transform: translateY(-2.5vh) scale(0.5);
       }
        50% {
        left: 90%;
        -webkit-transform: translateY(0vh) scale(0.45);
        transform: translateY(0vh) scale(0.45);
       }
        51% {
        left: 110%;
        -webkit-transform: translateY(0vh) scale(0.45);
        transform: translateY(0vh) scale(0.45);
       }
        100% {
        left: 110%;
        -webkit-transform: translateY(0vh) scale(0.45);
        transform: translateY(0vh) scale(0.45);
       }
       }
       @keyframes fly-right-two {
        0% {
        left: -10%;
        opacity: 1;
        -webkit-transform: translateY(-2vh) scale(0.5);
        transform: translateY(-2vh) scale(0.5);
       }
        10% {
        left: 10%;
        -webkit-transform: translateY(0vh) scale(0.4);
        transform: translateY(0vh) scale(0.4);
       }
        20% {
        left: 30%;
        -webkit-transform: translateY(-4vh) scale(0.6);
        transform: translateY(-4vh) scale(0.6);
       }
        30% {
        left: 50%;
        -webkit-transform: translateY(1vh) scale(0.45);
        transform: translateY(1vh) scale(0.45);
       }
        40% {
        left: 70%;
        -webkit-transform: translateY(-2.5vh) scale(0.5);
        transform: translateY(-2.5vh) scale(0.5);
       }
        50% {
        left: 90%;
        -webkit-transform: translateY(0vh) scale(0.45);
        transform: translateY(0vh) scale(0.45);
       }
        51% {
        left: 110%;
        -webkit-transform: translateY(0vh) scale(0.45);
        transform: translateY(0vh) scale(0.45);
       }
        100% {
        left: 110%;
        -webkit-transform: translateY(0vh) scale(0.45);
        transform: translateY(0vh) scale(0.45);
       }}
}