body {
    text-align: center;
    font-size: 20px;
    overflow-x: hidden;
    font-family: 'PT Sans', sans-serif;
    background: rgb(94,7,44);
    background: linear-gradient(90deg, rgba(94,7,44,0.4181022750897234) 0%, rgba(145,49,49,0.14359247116815477) 48%, rgba(0,198,255,1) 100%);

}

.profile {
    height: 350px;
    border-radius: 25px;
}

.hvr-grow {
    display: inline-block;
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
    transform: scale(1.1);
}

.title-fade-in {
    opacity: 100;
    animation-name: fade-in;
    -webkit-animation-name: fade-in;
    -moz-animation-name: fade-in;
    animation-duration: 4s;
}

.title-fade-out {
    opacity: 0;
    animation-name: fade-out;
    -webkit-animation-name: fade-out;
    -moz-animation-name: fade-out;
    animation-duration: 4s;
}


.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 4s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fade-out {
	opacity: 0;
	animation-name: fadeIOutOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 4s;
}

@keyframes fadeIOutOpacity {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

html {
    overflow-y: scroll;
}